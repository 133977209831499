import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import useForm from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { createProduct, updateProducts } from '../../services/products';
import Image from 'react-bootstrap/Image'
import { uploadFile } from '../../services/media';
import imageProduct from '../../assets/images/product.png';
import { getCategories } from '../../services/categories';

const ModalProduct = ({ showModal, setShowModal, dataProduct, isEdit, setRefresh, refresh }) => {

    const [categories, setCategories] = useState([])
    const [fileSend, setFileSend] = useState();

    const handleClose = () => {
        setShowModal(false)
    }

    useEffect(() => {
        getCategories().then((response) => {
            setCategories(response.data)
        })
    }, [])


    const [dataForm, handleChangeInput] = useForm({
        name: dataProduct.name,
        price: dataProduct.price,
        quantity: dataProduct.quantity,
        category: dataProduct.category?.id || '',
        image: dataProduct.image
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEdit) {
            //When is a Update
            if (dataProduct.name === dataForm.name && dataProduct.price === dataForm.price && dataProduct.quantity === dataForm.quantity && dataProduct.category?.id === dataForm.category && !fileSend) {
                Swal.fire(
                    'Oops!',
                    'No has hecho ningún cambio',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Update Product
                    const response = await updateProducts(dataProduct.id, dataForm)
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido actualizado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el producto',
                        'error'
                    )
                }
            }

        } else {
            //When is a new
            if (dataForm.name === '' && dataForm.price === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Create Product
                    const response = await createProduct(dataForm);
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido creado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el producto',
                        'error'
                    )
                }
            }
        }
    }

    const handleChangeFile = ({ target }) => {
        if (target.files.length) {
            const file = target.files[0];
            setFileSend(file);
        }else {
            setFileSend(undefined);
        }
    }

    const handleSendFile = async () => {
        if (fileSend) {
            const response = await uploadFile(fileSend, 'image');
            dataForm.image = response.secure_url;
            return response.secure_url;
        }else {
            return false;
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Editar Producto' : 'Nuevo Producto'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="name">Nombre</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="name" aria-describedby="name"
                            name="name"
                            value={dataForm.name}
                            onChange={handleChangeInput}
                            required
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="price">Precio</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="price" aria-describedby="price"
                            type="number"
                            name="price"
                            value={dataForm.price}
                            onChange={handleChangeInput}
                            placeholder="Precio del producto sin decimales"
                            required
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="quantity">Cantidad</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="quantity" aria-describedby="quantity"
                            type="number"
                            name="quantity"
                            value={dataForm.quantity}
                            onChange={handleChangeInput}
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="category">Categoría</InputGroup.Text>
                        </InputGroup.Prepend>
                        <select className='form-control'
                            name='category'
                            value={dataForm.category}
                            onChange={handleChangeInput}>
                            <option value="">Seleccione</option>
                            {
                                categories?.map((category, index) => (
                                    <option key={index} value={category.id}>{category.name}</option>
                                ))
                            }
                        </select>
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="image">Imagen</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Image fluid thumbnail src={dataProduct?.image ? dataProduct.image : imageProduct} alt={dataProduct.name} className="modal__product__image" />
                        <input type="file" onChange={handleChangeFile} className='form-control' />
                    </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}

export default ModalProduct
