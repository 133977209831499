const isLogged = () => {

    const token = localStorage.getItem('token')

    let myResponse = false;

    if (token) {
        myResponse = true
        
    }else {
        myResponse = false
    }

    return myResponse;
}

export default isLogged
