import React, { useContext } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AppContext } from '../../containers/Dashboard';
import { createOrder, updateOrder } from '../../services/order';
import { printCommand } from '../../services/printLocal';
import ModalActions from './ModalActions';

const ModalOrder = ({ showModal, setShowModal, categories, tables }) => {
    const { currentOrder, setCurrentOrder, detailsCurrentOrder, setDetailsCurrentOrder, dataPrint } = useContext(AppContext);

    console.log(tables)
    const handleClose = () => {
        setShowModal(false)
    }

    console.log('currentOrder', currentOrder)

    const handleSuccess = async () => {
        let date = new Date()
        if (!detailsCurrentOrder.table?.id) {
            Swal.fire(
                'Oops!',
                'No has seleccionado la mesa',
                'warning'
            )
        } else {
            Swal.showLoading();
            //print order
            let productsPrint = [];
            currentOrder.forEach(element => {
                let aditionsPrint = [];
                element.aditions.forEach(adit => {
                    aditionsPrint.push(adit.name);
                });
                if (element.comment) {
                    aditionsPrint.push(element.comment);
                }
                let newProduct = {
                    name: element.product.name,
                    quantity: element.quantity,
                    aditions: aditionsPrint
                }
                productsPrint.push(newProduct)
            });
            const dataPrinter = {
                customer: {
                    name: 'Publico'
                },
                sale: {
                    number: detailsCurrentOrder?.idOrder ? 'ADICIONAL' : 'N/A',
                    date: `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`,
                    table: `${detailsCurrentOrder.table.name} - ${detailsCurrentOrder.table.room?.name}`
                },
                products: productsPrint
            }
            await printCommand(dataPrinter, dataPrint.url);
            //Send order
            let total = 0;
            let productsSend = [];
            currentOrder.forEach(element => {
                total += element.quantity * element.product.price;
                let aditionsSend = '';
                element.aditions.forEach(adit => {
                    aditionsSend += `${adit.name} -`;
                });
                let newProductSend = {
                    orders_id: '+',
                    products_id: {
                        id: element.product.id
                    },
                    quantity: element.quantity,
                    subtotal: element.quantity * element.product.price,
                    comment: element.comment,
                    aditions: aditionsSend
                }
                productsSend.push(newProductSend);
            });
            const dataSend = {
                total: total,
                table: detailsCurrentOrder.table.id,
                products: {
                    create: productsSend
                }
            }
            try {
                //actualiza una orden
                if (detailsCurrentOrder?.idOrder) {
                    await updateOrder(detailsCurrentOrder.idOrder, {
                        total : detailsCurrentOrder.total + total,
                        products: {
                            create: productsSend
                        }
                    })
                }else {
                    //Crea una orden nueva
                    await createOrder(dataSend);
                }
                Swal.fire(
                    'Excelente!',
                    'Guardado exitosamente',
                    'success'
                )
            } catch (error) {
                Swal.fire(
                    'Oops!',
                    'Hubo un error al guardar',
                    'warning'
                )
            }
            //Clean
            setDetailsCurrentOrder({})
            setCurrentOrder([])
            setShowModal(false);
        }
    }

    const handleSelectTable = ({ target }) => {
        let table = tables.find(e => e.id === Number(target.value))
        setDetailsCurrentOrder({
            table: table
        })
    }

    const handleClean = () => {
        setDetailsCurrentOrder({})
        setCurrentOrder([])
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Pedido Actual</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    categories.map((category, index) => {
                        let items = currentOrder.filter(current => current.product.category.id === category.id)
                        if (items.length) {
                            return (
                                <div className="card mb-2 border-card" key={index}>
                                    <div className="card-header background-blue">
                                        {category.name}
                                    </div>
                                    <ul className="list-group list-group-flush modal__products">
                                        {
                                            currentOrder.map((product, indexProduct) => {
                                                if (product.product.category.id === category.id) {
                                                    return (
                                                        <li key={indexProduct} className="list-group-item d-flex justify-content-between">
                                                            <span>{product.product.name}</span>
                                                            <div className='modal__actions'>
                                                                <ModalActions product={product} index={indexProduct} />
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        }
                    })
                }
                <select
                    className='form-control'
                    onChange={handleSelectTable}
                    value={detailsCurrentOrder.table?.id}
                >
                    <option value="">Seleccionar Mesa</option>
                    {tables.map((table, index) => (
                        <option
                            value={table.id}
                            key={index}
                        >{`${table.name} - ${table.room?.name}`}
                        </option>
                    ))}
                </select>
                {
                    detailsCurrentOrder?.idOrder ?
                        <>
                            <Modal.Title>En Preparación</Modal.Title>
                            {
                                categories.map((category, index) => {
                                    let items = detailsCurrentOrder?.currentProducts.filter(current => current.products_id.category === category.id)
                                    if (items.length) {
                                        return (
                                            <div className="card mb-2 border-card" key={index}>
                                                <div className="card-header background-blue">
                                                    {category.name}
                                                </div>
                                                <ul className="list-group list-group-flush modal__products">
                                                    {
                                                        detailsCurrentOrder.currentProducts.map((product, indexProduct) => {
                                                            if (product.products_id.category === category.id) {
                                                                return (
                                                                    <li key={indexProduct} className="list-group-item d-flex justify-content-between">
                                                                        <span>{product.products_id.name}</span>
                                                                        <input type="number" 
                                                                        disabled
                                                                        className="form-control modal__input" value={product.quantity}  />
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </>
                        : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleClean}>
                    Borrar
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Continuar
                </Button>
                <Button variant="success" type="button" onClick={handleSuccess}>
                    Listo!
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalOrder