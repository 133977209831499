import axios from "axios";

export const getCategories = async () => {
    try {
        const response =  await axios.get('items/categories?limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const getCategoriesWithAditions = async () => {
    try {
        const response =  await axios.get('items/categories?fields=*,aditions.*.*&limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const getCategoriesWithProducts = async () => {
    try {
        const response =  await axios.get('items/categories?fields=*,products.*.*.*.*&limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const createCategory = async (data) => {
    try {
        const response =  await axios.post(`items/categories`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateCategory = async (id, data) => {
    try {
        const response =  await axios.patch(`items/categories/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteCategory = async (id) => {
    try {
        const response =  await axios.delete(`items/categories/${id}`);
        console.log(response)
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return error;
    }
}