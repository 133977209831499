import React, { useState, useEffect } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useForm from '../../../hooks/useForm';
import { getOrdersPayed } from '../../../services/order';
import { numberToMoney, parsedDate } from '../../../utils/locations';
import DataTables from '../../DataTables';
import Export from '../../excel/Export';

const ReportProducts = () => {

  const [products, setProducts] = useState([]);

  const columns = [
    {
      dataField: 'product.name',
      text: 'Producto',
      sort: true
    },
    {
      dataField: 'quantity',
      text: 'Cantidad',
      sort: true
    },
    {
      dataField: 'product.price',
      text: 'Precio',
      formatter: (cellContent, row) => {
        return <span>{numberToMoney(cellContent)}</span>
      }
    },
    {
      dataField: 'subtotal',
      text: 'Total',
      formatter: (cellContent, row) => {
        return <span>{numberToMoney(cellContent)}</span>
      }
    },
    {
      dataField: 'product.quantity',
      text: 'Cantidad Actual',
      sort: true
    },
  ];

  const excelColumns = [
    {
      text: 'Producto',
      dataField: (col) => {
        return col.product.name
      },
    },
    {
      dataField: 'quantity',
      text: 'Cantidad',
    },
    {
      dataField: (col) => {
        return col.product.price
      },
      text: 'Precio',
    },
    {
      dataField: 'subtotal',
      text: 'Total',
    },
    {
      dataField: (col) => {
        return col.product.quantity
      },
      text: 'Cantidad Actual',
    }
  ]

  const [dataForm, handleChangeInput] = useForm({
    date_start: parsedDate(),
    date_end: parsedDate(),
  })

  const handleSearch = async () => {
    Swal.showLoading();
    let dateStart = new Date(dataForm.date_start);
    dateStart.setMinutes(dateStart.getMinutes() + dateStart.getTimezoneOffset())
    let dateEnd = new Date(dataForm.date_end);
    dateEnd.setMinutes(dateEnd.getMinutes() + dateEnd.getTimezoneOffset())
    const response = await getOrdersPayed(parsedDate(dateStart), parsedDate(dateEnd))
    let productsList = [];
    response.data.forEach(element => {
      element.products.forEach((el) => {
        const newProduct = {
          id: el.products_id.id,
          quantity: el.quantity,
          product: el.products_id,
          subtotal: el.subtotal
        }
        let index = productsList.findIndex(e => e.product.id === newProduct.product.id)
        if (index >= 0) {
          productsList[index].quantity = productsList[index].quantity + newProduct.quantity;
          productsList[index].subtotal = productsList[index].subtotal + (newProduct.quantity * newProduct.product.price)
        } else {
          productsList.push(newProduct)
        }
      })
    });
    setProducts(productsList);
    Swal.close();
  }

  useEffect(() => {
    handleSearch();
  }, [])

  return (
    <>
      <h1 className="h3 mb-2 text-gray-800">Reporte de Productos</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Listado de productos vendidos</h6>
          <Export
            data={products}
            columns={excelColumns}
            fileName="Reporte de Productos Vendidos"
            sheetName={`${dataForm.date_start}__${dataForm.date_end}`}
          />
        </div>
        <div className="card-body">
          <div className="d-flex">
            <InputGroup size="sm" className="mb-3 mr-2">
              <InputGroup.Prepend>
                <InputGroup.Text id="date_start">Fecha Inicial</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                aria-label="date_start"
                aria-describedby="date_start"
                name="date_start"
                value={dataForm.date_start}
                onChange={handleChangeInput}
                max={dataForm.date_end}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3 ml-2 mr-2">
              <InputGroup.Prepend>
                <InputGroup.Text id="date_end">Fecha Final</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="date"
                aria-label="date_end"
                aria-describedby="date_end"
                name="date_end"
                value={dataForm.date_end}
                onChange={handleChangeInput}
                min={dataForm.date_start}
              />
            </InputGroup>
            <button className="btn btn-primary btn-sm ml-2" onClick={handleSearch}>
              <span>Buscar</span>
            </button>
          </div>
          <div className="d-flex">
            <div className='alert alert-primary'>
              <strong>Total: {products.length ? numberToMoney(products.reduce((a, b) => Number(a) + Number(b.subtotal), 0)) : numberToMoney(0)}</strong>
            </div>
            <div className='alert alert-primary ml-2'>
              <strong>Total productos: {products.length ? products.reduce((a, b) => a + b.quantity, 0) : 0}</strong>
            </div>
            <div className='alert alert-primary ml-2'>
              <strong>Cantidad de ítems: {products.length ? products.length : 0}</strong>
            </div>
          </div>
          <DataTables data={products} columns={columns} />
        </div>
      </div>
    </>
  )
}

export default ReportProducts