import React, { useMemo } from 'react'
import ReactExport from "react-export-excel";
import { parsedDate } from '../../utils/locations';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Export = ({ data, columns, fileName = "Reporte_Restaurante", sheetName }) => {

    const date = useMemo(() => {
        let newDate = new Date();
        return parsedDate(newDate);
    }, [])

    return (
        <ExcelFile
            element={
                <button className="btn btn-success">
                    <i className="fa fa-download"></i>
                </button>
            }
            filename={fileName}
        >
            <ExcelSheet data={data} name={sheetName ? sheetName : `Reporte_${date}`}>
                {
                    columns?.map((column, index) => (
                        <ExcelColumn key={index} label={column.text} value={column.dataField} />
                    ))
                }
            </ExcelSheet>
        </ExcelFile>
    )
}

export default Export