import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ toggleSidebar, setToggleSidebar, sessionVerify, setSessionVerify }) => {
    let className = toggleSidebar ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion';

    return (
        <ul className={className}
            id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-utensils"></i>
                </div>
                <div className="sidebar-brand-text mx-3">URAWEB RESTAURANTE</div>
            </Link>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Escritorio</span>
                </Link>
            </li>

            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/order">
                    <i className="fas fa-receipt"></i>
                    <span>Pedidos</span>
                </Link>
            </li>
            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/register">
                    <i className="fas fa-cash-register"></i>
                    <span>Caja</span>
                </Link>
            </li>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Opciones
            </div>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseProducts"
                    aria-expanded="true" aria-controls="collapseProducts">
                    <i className="fas fa-fw fa-box"></i>
                    <span>Productos</span>
                </Link>
                <div id="collapseProducts" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Productos:</h6>
                        <Link className="collapse-item" to="/products" onClick={() => { setSessionVerify(!sessionVerify) }}>Productos</Link>
                        <Link className="collapse-item" to="/categories" onClick={() => { setSessionVerify(!sessionVerify) }}>Categorías</Link>
                        <Link className="collapse-item" to="/aditions" onClick={() => { setSessionVerify(!sessionVerify) }}>Adiciones</Link>
                    </div>
                </div>
            </li>

            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/customers">
                    <i className="fas fa-fw fa-user-tie"></i>
                    <span>Clientes</span>
                </Link>
            </li>

            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">
                Reportes
            </div>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    <i className="fas fa-fw fa-paste"></i>
                    <span>Ventas</span>
                </Link>
                <div id="collapseOne" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">REPORTES:</h6>
                        <Link className="collapse-item" to="/report-sales" onClick={() => { setSessionVerify(!sessionVerify) }}>Informe Diario</Link>
                        <Link className="collapse-item" to="/report-products" onClick={() => { setSessionVerify(!sessionVerify) }}>Productos Vendidos</Link>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="sidebar-heading">
                Configuraciones
            </div>

            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/business">
                    <i className="fas fa-fw fa-building"></i>
                    <span>Mi Empresa</span>
                </Link>
            </li>
            <li className="nav-item" onClick={() => { setSessionVerify(!sessionVerify) }}>
                <Link className="nav-link" to="/users">
                    <i className="fas fa-fw fa-users-cog"></i>
                    <span>Usuarios</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-cog"></i>
                    <span>Generales</span>
                </Link>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">GENERALES:</h6>
                        <Link className="collapse-item" to="/print" onClick={() => { setSessionVerify(!sessionVerify) }}>Impresión</Link>
                        <Link className="collapse-item" to="/billing" onClick={() => { setSessionVerify(!sessionVerify) }}>Facturación</Link>
                    </div>
                </div>
            </li>
            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseSpace"
                    aria-expanded="true" aria-controls="collapseSpace">
                    <i className="fab fa-buromobelexperte"></i>
                    <span>Espacio</span>
                </Link>
                <div id="collapseSpace" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Espacio:</h6>
                        <Link className="collapse-item" to="/rooms" onClick={() => { setSessionVerify(!sessionVerify) }}>Salas</Link>
                        <Link className="collapse-item" to="/tables" onClick={() => { setSessionVerify(!sessionVerify) }}>Mesas</Link>
                    </div>
                </div>
            </li>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"
                    onClick={() => { setToggleSidebar(!toggleSidebar) }}
                ></button>
            </div>
        </ul>
    )
}

export default Sidebar
