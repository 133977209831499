import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { deleteProduct, getProducts } from '../../services/products'
import DataTables from '../DataTables'
import Export from '../excel/Export'
import ModalProduct from './ModalProduct'

const Products = () => {

    const [products, setProducts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [isEdit, setIsEdit] = useState(false)

    const columns = [
        {
            dataField: 'name',
            text: 'Artículo',
            sort: true
        },
        {
            dataField: 'price',
            text: 'Precio',
            sort: true
        },
        {
            dataField: 'quantity',
            text: 'Cantidad',
            sort: true
        },
        {
            dataField: 'category.name',
            text: 'Categoría',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => { handleEdit(row) }}>
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => { handleDelete(row) }}>
                            <i className="fas fa-trash"></i>
                        </span>
                    </>
                );
            }
        }
    ];

    const excelColumns = [
        {
            dataField: 'name',
            text: 'Artículo',
        },
        {
            dataField: 'price',
            text: 'Precio',
        },
        {
            dataField: 'quantity',
            text: 'Cantidad',
        },
        {
            dataField: (col) => {
                return col.category.name
            },
            text: 'Categoría',
        },
    ];

    const handleEdit = (product) => {
        setIsEdit(true)
        setShowModal(true)
        setDataModal(product)
    }

    const handleNew = () => {
        setIsEdit(false)
        setShowModal(true)
        setDataModal({
            name: '',
            price: '',
            quantity: '',
            category: ''
        })
    }

    const handleDelete = async (product) => {
        Swal.fire({
            title: `Está seguro de eliminar el ${product.name} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                try {
                    await deleteProduct(product.id);
                    Swal.fire(
                        'Eliminado!',
                        'El producto ha sido eliminado',
                        'success'
                    )
                    setRefresh(!refresh);
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }

            }
        })
    }

    useEffect(() => {
        Swal.showLoading();
        getProducts().then((response) => {
            setProducts(response.data)
            Swal.close();
        })
    }, [refresh])


    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Productos</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Listado de Productos</h6>
                    <div>
                        <button className="btn btn-primary mr-2" onClick={handleNew}>
                            <span className="text">Nuevo Producto</span>
                        </button>
                        <Export
                            data={products}
                            columns={excelColumns}
                            fileName="Reporte de Productos"
                        />
                    </div>
                </div>
                <div className="card-body">
                    <DataTables data={products} columns={columns} />
                </div>
            </div>
            {
                showModal &&
                <ModalProduct
                    setShowModal={setShowModal}
                    showModal={showModal}
                    dataProduct={dataModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    isEdit={isEdit}
                />
            }
        </>
    )
}

export default Products
