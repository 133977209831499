import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { AppContext } from '../../containers/Dashboard';

const ModalCustom = ({ showModal, setShowModal, product, index }) => {

    const { currentOrder, setCurrentOrder } = useContext(AppContext);

    const handleClose = () => {
        setShowModal(false)
    }

    const handleComment = ({ target }) => {
        let dummieOrder = [...currentOrder];
        dummieOrder[index].comment = target.value;
        setCurrentOrder(dummieOrder);
    }

    const onSelect = (target, adition) => {
        let dummieOrder = [...currentOrder];
        if (target.checked) {
            dummieOrder[index].aditions.push(adition)
        } else {
            let indexAdition = dummieOrder[index].aditions.findIndex(e => e.id === adition.id)
            dummieOrder[index].aditions.splice(indexAdition, 1);
        }
        setCurrentOrder(dummieOrder);
    }

    return (
        <Modal show={showModal} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Adiciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal__aditions">
                    {
                        product.product.category.aditions.map((adition, indexAdi) => (
                            <div key={indexAdi}>
                                <input
                                    className="styled-checkbox"
                                    id={`check-${indexAdi}`}
                                    type="checkbox"
                                    value={adition.aditions_id?.id}
                                    onChange={({ target }) => { onSelect(target, adition.aditions_id) }}
                                    checked={currentOrder[index].aditions.some(e => e.id === adition.aditions_id?.id)}
                                />
                                <label for={`check-${indexAdi}`}>{adition.aditions_id?.name}</label>
                            </div>
                        ))
                    }
                    <input
                        type="text"
                        className='form-control'
                        placeholder='Comentario'
                        onChange={handleComment}
                        value={currentOrder[index].comment}
                    />
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ModalCustom