import React, { useEffect, useState } from 'react'
import { Button, FormControl, Image, InputGroup } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import { createCategory, deleteCategory, getCategoriesWithAditions, updateCategory } from '../../services/categories'
import DataTables from '../DataTables'
import imageProduct from '../../assets/images/category.png';
import Swal from 'sweetalert2';
import { uploadFile } from '../../services/media';
import { getAditions } from '../../services/aditions';

const Categories = () => {

    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [fileSend, setFileSend] = useState();
    const [aditions, setAditions] = useState([]);
    const [aditionsForm, setAditionsForm] = useState([])

    const [dataForm, handleChangeInput, handleChangeForm, reset] = useForm({
        name: '',
    });

    useEffect(() => {
        getAditions().then((response) => {
            setAditions(response.data)
        })
    }, []);


    useEffect(() => {
        getCategoriesWithAditions().then((response) => {
            if (response) {
                setData(response.data)
                console.log(response.data)

            }
        })
    }, [refresh])

    const columns = [
        {
            dataField: 'name',
            text: 'Categoría',
            sort: true
        },
        {
            dataField: 'aditions',
            text: 'Adiciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    row.aditions.map((adition, index) => (
                        <span className="badge badge-secondary mr-2" key={index}>{adition.aditions_id?.name}</span>
                    ))
                )
            }
        },
        {
            dataField: 'image',
            text: 'Imagen',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Image fluid thumbnail src={row?.image ? row.image : imageProduct} alt={row.name} className="modal__product__image" />
                    </>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => { handleEdit(row) }}>
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => { handleDelete(row) }}>
                            <i className="fas fa-trash"></i>
                        </span>
                    </>
                );
            }
        }
    ]

    const handleEdit = (category) => {
        let myAditions = [];
        category.aditions.forEach(adition => {
            myAditions.push(adition.aditions_id.id);
        });
        setIsEdit(true);
        setAditionsForm(myAditions)
        handleChangeForm({
            ...category
        })
    }

    const handleDelete = (category) => {
        Swal.fire({
            title: `Está seguro de eliminar a ${category.name} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                try {
                    const response = await deleteCategory(category.id);
                    if (response) {
                        Swal.fire(
                            'Eliminado!',
                            'Eliminado con éxito',
                            'success'
                        )
                        setRefresh(!refresh);
                    } else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }

            }
        })
    }

    const handleSendFile = async () => {
        if (fileSend) {
            const response = await uploadFile(fileSend, 'image');
            dataForm.image = response.secure_url;
            setFileSend(undefined);
            return response.secure_url;
        } else {
            return false;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        //process Aditions
        const sendAditions = [];
        let dataSend = {...dataForm};
        aditionsForm.forEach(element => {
            sendAditions.push({
                categories_id: isEdit ? dataForm.id : '+',
                aditions_id: {
                    id: element
                }
            })
        });
        if (isEdit) {
            let aditionsDeleted = [];
            dataForm.aditions.forEach(element => {
                aditionsDeleted.push(element.id);
            })
            dataSend.aditions = {
                delete: aditionsDeleted,
                create: sendAditions
            }
            if (dataSend.name === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Update Product
                    const response = await updateCategory(dataSend.id, dataSend)
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido actualizado exitosamente',
                            'success'
                        )
                        setRefresh(!refresh)
                        setIsEdit(false);
                        reset();
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el producto',
                        'error'
                    )
                }
            }
        } else {
            dataSend.aditions = {
                create: sendAditions
            }
            if (dataSend.name === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Create Product
                    const response = await createCategory(dataSend);
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido creado exitosamente',
                            'success'
                        )
                        setRefresh(!refresh)
                        reset();
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el producto',
                        'error'
                    )
                }
            }
        }
    }

    const handleChangeFile = ({ target }) => {
        if (target.files.length) {
            const file = target.files[0];
            setFileSend(file);
        } else {
            setFileSend(undefined);
        }
    }

    const handleCheckbox = (target, id) => {
        const currentAditions = [...aditionsForm];
        if (target.checked) {
            currentAditions.push(id);
        } else {
            let index = currentAditions.findIndex(element => element === id);
            currentAditions.splice(index, 1);
        }
        setAditionsForm(currentAditions)
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Categorías</h1>
            <div className="row">
                <div className="col-md-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Lista de Categorías</h6>

                        </div>
                        <div className="card-body">
                            <DataTables data={data} columns={columns} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-4">
                        <form onSubmit={handleSubmit}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">{!isEdit ? 'Nueva Categoría' : 'Editar Categoría'}</h6>
                            </div>
                            <div className="card-body">
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="name">Categoría</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="name" aria-describedby="name"
                                        name="name"
                                        value={dataForm.name}
                                        onChange={handleChangeInput}
                                        placeholder="Nombre de la categoría"
                                        required
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="image">Imagen</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <input type="file" onChange={handleChangeFile} className='form-control' />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="aditions">Adiciones</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    {
                                        aditions.map((adition, index) => {
                                            return (
                                                <div className="mr-2 custom-control custom-checkbox" key={index}>
                                                    <label>{adition.name}
                                                        <input type="checkbox" name="aditions" onChange={({ target }) => { handleCheckbox(target, adition.id) }} checked={aditionsForm.some(element => element === adition.id)} />
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </InputGroup>
                            </div>
                            <div className="card-footer d-flex flex-row-reverse">
                                <Button variant="success" type="submit">
                                    Guardar
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Categories