import axios from "axios";

export const getRooms = async () => {
    try {
        const response =  await axios.get('items/rooms?limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const createRoom = async (data) => {
    try {
        const response =  await axios.post(`items/rooms`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateRoom = async (id, data) => {
    try {
        const response =  await axios.patch(`items/rooms/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteRoom = async (id) => {
    try {
        const response =  await axios.delete(`items/rooms/${id}`);
        console.log(response)
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return error;
    }
}