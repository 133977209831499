import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import useForm from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { createUser, getRoles, updateUser } from '../../services/users';

const ModalUser = ({ showModal, setShowModal, dataModal, isEdit, setRefresh, refresh }) => {

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles().then((response) => {
            setRoles(response.data)
        })
    }, []);

    const handleClose = () => {
        setShowModal(false)
    }

    const [dataForm, handleChangeInput] = useForm({
        first_name: dataModal.first_name,
        last_name: dataModal.last_name,
        email: dataModal.email,
        role: dataModal.role?.id,
        password: ''
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        let data = {}
        if (isEdit) {
            //When is a Update
            if (dataForm.password === '') {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    role: dataForm.role,
                }
            } else {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: dataForm.password,
                    role: dataForm.role,
                }
            }

            if (dataModal.first_name === dataForm.first_name && dataModal.last_name === dataForm.last_name && dataModal.email === dataForm.email && dataForm.password === '' && dataModal.role?.id === dataForm.role) {
                Swal.fire(
                    'Oops!',
                    'No has hecho ningún cambio',
                    'warning'
                )
            } else {
                Swal.showLoading()
                try {
                    const response = await updateUser(dataModal.id, data)
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El Usuario ha sido actualizado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el usuario',
                        'error'
                    )
                }
            }

        } else {
            //When is a new
            if (dataForm.password === '') {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: '12345678',
                    role: dataForm.role,
                    status: 'active'
                }
            } else {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: dataForm.password,
                    role: dataForm.role,
                    status: 'active'
                }
            }

            if (dataForm.first_name === '' && dataForm.last_name === '' && dataForm.email === '' && dataForm.password === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading()
                try {
                    const response = await createUser(data);
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'El Usuario ha sido creado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el usuario',
                        'error'
                    )
                }
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Editar Usuario' : 'Nuevo Usuario'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="first_name">Nombres</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="first_name" aria-describedby="first_name"
                            name="first_name"
                            value={dataForm.first_name}
                            onChange={handleChangeInput}
                            placeholder="Nombres Completos"
                            required
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="last_name">Apellidos</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="last_name" aria-describedby="last_name"
                            type="text"
                            name="last_name"
                            value={dataForm.last_name}
                            onChange={handleChangeInput}
                            placeholder="Apellidos Completos"
                            required
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="email">Email</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="email" aria-describedby="email"
                            type="email"
                            name="email"
                            value={dataForm.email}
                            onChange={handleChangeInput}
                            placeholder="Correo electrónico"
                            required
                        />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="role">Rol</InputGroup.Text>
                        </InputGroup.Prepend>
                        <select className='form-control'
                            name='role'
                            value={dataForm.role}
                            onChange={handleChangeInput}>
                            <option value="">Seleccione</option>
                            {
                                roles?.map((role, index) => (
                                    <option key={index} value={role.id}>{role.name}</option>
                                ))
                            }
                        </select>
                    </InputGroup>

                    <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="password">Contraseña</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="password" aria-describedby="password"
                            type="password"
                            name="password"
                            value={dataForm.password}
                            onChange={handleChangeInput}
                        />
                    </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}

export default ModalUser
