import React from 'react'

const Desktop = () => {
    return (
        <>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Bienvenido otra vez...</h6>
            </div>
            <div className="card-body">
                <div className="text-center">
                    <img className="img-fluid px-3 px-sm-4 mt-3 mb-4"
                        src="/img/undraw_posting_photo.svg" alt="..." />
                </div>
                <p>Este software funciona en móviles, tablets o computadores con conexión a Internet.</p>
            </div>
        </div>
        </>
    )
}

export default Desktop
