import axios from "axios";
import { parsedDateServer } from "../utils/locations";

export const createOrder = async (data) => {
    try {
        const response =  await axios.post(`items/orders`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateOrder = async (id, data) => {
    try {
        const response =  await axios.patch(`items/orders/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.log('er',error)
        return error;
    }
}

export const getOrders = async () => {
    try {
        const response =  await axios.get(`items/orders?fields=*.*.*&filter[status]=open&limit=-1`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteOrder = async (id) => {
    try {
        const response =  await axios.delete(`items/orders/${id}`);
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        console.log('er',error)
        return error;
    }
}

export const getOrdersPayed = async (dateStart, dateEnd) => {
    try {
        const response =  await axios.get(`items/orders?fields=*.*.*&filter[status]=payed&filter[date_created][_between]=${parsedDateServer(dateStart, true)},${parsedDateServer(dateEnd)}&limit=-1`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}