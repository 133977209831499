export const numberToMoney = (number) => {
    return new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}).format(number);
}

export const parsedDate = (date) => {
    date = !date ? new Date() : date;
    const getYear = date.toLocaleString("default", { year: "numeric" });
    const getMonth = date.toLocaleString("default", { month: "2-digit" });
    const getDay = date.toLocaleString("default", { day: "2-digit" });
    return getYear + "-" + getMonth + "-" + getDay;
}

export const parsedDateServer = (dateInput, isStart) => {
    let date = new Date(dateInput)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    const getYear = date.toLocaleString("default", { year: "numeric" });
    const getMonth = date.toLocaleString("default", { month: "2-digit" });
    const getDay = date.toLocaleString("default", { day: "2-digit" });
    if (isStart) {
        return `${getYear}-${getMonth}-${Number(getDay)} 05:00:00`
    }else{
        return `${getYear}-${getMonth}-${Number(getDay) + 1} 04:59:59`
    }
}