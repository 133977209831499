import types from "../type/types";

const initialState = false;

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DO_LOGIN:
            return action.payload
    
        default:
            return state;
    }
}

export default LoginReducer
