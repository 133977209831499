import React, { useState, useEffect, createContext } from 'react'
import Sidebar from '../components/navigation/Sidebar'
import TopBar from '../components/navigation/TopBar'
import Footer from '../components/navigation/Footer'
import { Link } from 'react-router-dom'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Products from '../components/products/Products'
import Business from '../components/Business'
import '../utils/axiosConfig'
import getUserAuth from '../utils/getUserAuth'
import Users from '../components/users/Users'
import Desktop from '../components/Desktop'
import Customers from '../components/customers/Customers'
import Categories from '../components/categories/Categories'
import Aditions from '../components/aditions/Aditions'
import Rooms from '../components/rooms/Rooms'
import Tables from '../components/tables/Tables'
import Billing from '../components/billing/Billing'
import PrintConfig from '../components/print-config/PrintConfig'
import Register from '../components/register/Register'
import Order from '../components/order/Order'
import { getPrintConfig } from '../services/print-config'
import { getCompany } from '../services/company'
import { getBilling } from '../services/billing'
import ReportDaily from '../components/reports/daily/ReportDaily'
import ReportProducts from '../components/reports/products/ReportProducts'

export const AppContext = createContext({});

const Dashboard = () => {

    const [sessionVerify, setSessionVerify] = useState(true);
    const [currentOrder, setCurrentOrder] = useState([]);
    const [detailsCurrentOrder, setDetailsCurrentOrder] = useState({});
    const [dataPrint, setDataPrint] = useState({})
    const [dataCompany, setDataCompany] = useState({})

    useEffect(() => {
        getUserAuth()
    }, [sessionVerify])

    useEffect(() => {
        getPrintConfig().then((response) => {
            let baseURL = `http://${response.data.ip_pc}:${response.data.port_pc}`
            getBilling().then(responseBill => {
                setDataPrint({
                    ...responseBill.data,
                    url: baseURL
                })
            })
        })

        getCompany().then((response) => {
            setDataCompany(response.data);
        })
    }, [])
    

    const [toggleSidebar, setToggleSidebar] = useState(false)
    let className = toggleSidebar ? 'sidebar-toggled' : '';

    return (
        <AppContext.Provider value={{
            currentOrder,
            setCurrentOrder,
            detailsCurrentOrder,
            setDetailsCurrentOrder,
            dataPrint,
            dataCompany
        }}>
            <Router>
                <div id="page-top" className={className}>
                    <div id="wrapper">
                        <Sidebar
                            toggleSidebar={toggleSidebar}
                            setToggleSidebar={setToggleSidebar}
                            sessionVerify={sessionVerify}
                            setSessionVerify={setSessionVerify}
                        />
                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content">
                                <TopBar
                                    toggleSidebar={toggleSidebar}
                                    setToggleSidebar={setToggleSidebar}
                                />
                                <div className="container-fluid">
                                    <Switch>
                                        <Route exact path="/products" component={Products} />
                                        <Route exact path="/business" component={Business} />
                                        <Route exact path="/users" component={Users} />
                                        <Route exact path="/customers" component={Customers} />
                                        <Route exact path="/categories" component={Categories} />
                                        <Route exact path="/aditions" component={Aditions} />
                                        <Route exact path="/rooms" component={Rooms} />
                                        <Route exact path="/tables" component={Tables} />
                                        <Route exact path="/billing" component={Billing} />
                                        <Route exact path="/print" component={PrintConfig} />
                                        <Route exact path="/register" component={Register} />
                                        <Route exact path="/order" component={Order} />
                                        <Route exact path="/report-sales" component={ReportDaily} />
                                        <Route exact path="/report-products" component={ReportProducts} />
                                        <Route exact path="/" component={Desktop} />
                                    </Switch>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                    <Link className="scroll-to-top rounded" to="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </Link>
                </div>
            </Router>
        </AppContext.Provider>
    )
}

export default Dashboard
