import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import { createTable, deleteTable, getTables, updateTable } from '../../services/tables'
import DataTables from '../DataTables'
import Swal from 'sweetalert2';
import { getRooms } from '../../services/rooms';

const Tables = () => {

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [rooms, setRooms] = useState([]);

  const [dataForm, handleChangeInput, handleChangeForm, reset] = useForm({
    name: '',
    room: '',
  });

  useEffect(() => {
    getRooms().then((response) => {
      setRooms(response.data)
    })
  }, []);

  useEffect(() => {
    getTables().then((response) => {
      if (response) {
        console.log(response.data)
        setData(response.data)
      }
    })
  }, [refresh])

  const columns = [
    {
      dataField: 'name',
      text: 'Mesa',
      sort: true
    },
    {
      dataField: 'room.name',
      text: 'Sala',
      sort: true
    },
    {
      dataField: 'actions',
      text: 'Acciones',
      isDummyField: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => { handleEdit(row) }}>
              <i className="fas fa-pencil-alt"></i>
            </span>
            <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => { handleDelete(row) }}>
              <i className="fas fa-trash"></i>
            </span>
          </>
        );
      }
    }
  ]

  const handleEdit = (adition) => {
    setIsEdit(true);
    handleChangeForm({
      ...adition,
      room: adition.room.id
    })
  }

  const handleDelete = (adition) => {
    Swal.fire({
      title: `Está seguro de eliminar a ${adition.name} ?`,
      text: "Esta acción no es reversible!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Eliminarlo!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.showLoading()
          const response = await deleteTable(adition.id);
          if (response) {
            Swal.fire(
              'Eliminado!',
              'Eliminado con éxito',
              'success'
            )
            setRefresh(!refresh);
          } else {
            Swal.fire(
              'Error!',
              'Hubo un error al eliminar',
              'error'
            )
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'Hubo un error al eliminar',
            'error'
          )
        }

      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEdit) {
      if (dataForm.name === '') {
        Swal.fire(
          'Oops!',
          'Faltan datos por llenar',
          'warning'
        )
      } else {
        Swal.showLoading()
        try {
          //Update Product
          const response = await updateTable(dataForm.id, dataForm)
          if (response) {
            Swal.fire(
              'Excelente!',
              'Ha sido actualizado exitosamente',
              'success'
            )
            setRefresh(!refresh)
            setIsEdit(false);
            reset();
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'Hubo un error al actualizar',
            'error'
          )
        }
      }
    } else {
      if (dataForm.name === '') {
        Swal.fire(
          'Oops!',
          'Faltan datos por llenar',
          'warning'
        )
      } else {
        Swal.showLoading()
        try {
          //Create Product
          const response = await createTable(dataForm);
          if (response) {
            Swal.fire(
              'Excelente!',
              'Ha sido creado exitosamente',
              'success'
            )
            setRefresh(!refresh)
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'Hubo un error al guardar',
            'error'
          )
        }
      }
    }
  }

  return (
    <>
      <h1 className="h3 mb-2 text-gray-800">Mesas</h1>
      <div className="row">
        <div className="col-md-6">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Lista de Mesas</h6>

            </div>
            <div className="card-body">
              <DataTables data={data} columns={columns} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card shadow mb-4">
            <form onSubmit={handleSubmit}>
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">{!isEdit ? 'Nueva Mesa' : 'Editar Mesa'}</h6>
              </div>
              <div className="card-body">
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="name">Mesa</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl aria-label="name" aria-describedby="name"
                    name="name"
                    value={dataForm.name}
                    onChange={handleChangeInput}
                    placeholder="Nombre de la mesa"
                    required
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="room">Sala</InputGroup.Text>
                  </InputGroup.Prepend>
                  <select className='form-control'
                    name='room'
                    value={dataForm.room}
                    onChange={handleChangeInput}>
                    <option value="">Seleccione</option>
                    {
                      rooms?.map((room, index) => (
                        <option key={index} value={room.id}>{room.name}</option>
                      ))
                    }
                  </select>
                </InputGroup>
              </div>
              <div className="card-footer d-flex flex-row-reverse">
                <Button variant="success" type="submit">
                  Guardar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default Tables