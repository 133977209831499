import axios from "axios";

export const getAditions = async () => {
    try {
        const response =  await axios.get('items/aditions?limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const createAdition = async (data) => {
    try {
        const response =  await axios.post(`items/aditions`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateAdition = async (id, data) => {
    try {
        const response =  await axios.patch(`items/aditions/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteAdition = async (id) => {
    try {
        const response =  await axios.delete(`items/aditions/${id}`);
        console.log(response)
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return error;
    }
}