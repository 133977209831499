import React, { useEffect, useState, useContext } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { AppContext } from '../../containers/Dashboard';
import { getCategoriesWithProducts } from '../../services/categories'
import { getTables } from '../../services/tables';
import ModalOrder from './ModalOrder';
import Product from './Product';
import './style.css';

const Order = () => {

    const { currentOrder, detailsCurrentOrder } = useContext(AppContext);
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [tables, setTables] = useState([])

    useEffect(() => {
        Swal.showLoading()
        getCategoriesWithProducts().then(response => {
            setCategories(response.data);
            Swal.close();
        })
        getTables().then(response => {
            setTables(response.data);
        })
    }, [])

    const handleShowOrder = () => {
        setShowModal(true);
    }

    return (
        <>
            <ModalOrder
                setShowModal={setShowModal}
                showModal={showModal}
                categories={categories}
                tables={tables}
            />
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Pedidos</h6>
                    {
                        (currentOrder.length > 0 || detailsCurrentOrder?.idOrder) && (
                            <button className="btn btn-primary" onClick={handleShowOrder}>
                                <span className="text">Ver Orden Actual</span>
                            </button>
                        )
                    }
                </div>
                <div className="card-body">
                    <Accordion>
                        {
                            categories.map((category, index) => (
                                <Card key={index}>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey={index + 1} className="order__categories" >
                                        {category.name}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body>
                                            <div className="d-flex product__container">
                                                {
                                                    category.products.map((product, indexProduct) => (
                                                        <Product product={product} key={indexProduct} openModal={() => { setShowModal(true) }} />
                                                    ))
                                                }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))
                        }
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default Order