import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { deleteCustomer, getCustomers } from '../../services/customers'
import DataTables from '../DataTables'
import ModalCustomer from './ModalCustomer'

const Customers = () => {

    const [customers, setCustomers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        Swal.showLoading();
        getCustomers().then((response) => {
            if (response) {
                setCustomers(response.data)
            }
            Swal.close();
        })
    }, [refresh])

    const handleNew = () => {
        setIsEdit(false)
        setShowModal(true)
        setDataModal({
            fullname: '',
            phone: '',
            address: '',
            city: '',
            document_id: ''
        })
    }

    const handleEdit = (customer) => {
        setIsEdit(true)
        setShowModal(true)
        setDataModal(customer)
    }

    const handleDelete = (customer) => {
        Swal.fire({
            title: `Está seguro de eliminar a ${customer.fullname} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                try {
                    const response = await deleteCustomer(customer.id);
                    if (response) {
                        Swal.fire(
                            'Eliminado!',
                            'El Cliente ha sido eliminado',
                            'success'
                        )
                        setRefresh(!refresh);
                    }else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }
                
            }
        })
    }

    const columns = [
        {
            dataField: 'fullname',
            text: 'Nombre',
            sort: true
        },
        {
            dataField: 'document_id',
            text: 'Identificación'
        },
        {
            dataField: 'phone',
            text: 'Teléfono'
        },
        {
            dataField: 'address',
            text: 'Dirección'
        },
        {
            dataField: 'city',
            text: 'Ciudad',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                  <>
                    <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => {handleEdit(row)}}>
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                    <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => {handleDelete(row)}}>
                        <i className="fas fa-trash"></i>
                    </span>
                  </>
                );
              }
        }
    ]

    return (
        <>
        <h1 className="h3 mb-2 text-gray-800">Clientes</h1>
        <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Listado de Clientes</h6>
                <button className="btn btn-primary" onClick={handleNew}>
                    <span className="text">Nuevo Cliente</span>
                </button>
            </div>
            <div className="card-body">
                <DataTables data={customers} columns={columns} />
            </div>
        </div>
        {
            showModal && 
            <ModalCustomer
                setShowModal = {setShowModal}
                showModal = {showModal}
                dataModal = {dataModal}
                setRefresh = {setRefresh}
                refresh = {refresh}
                isEdit = {isEdit}
            />
        }
        </>
    )
}

export default Customers
