import axios from "axios";

export const configPrinter = async (url, data) => {
    const instance = axios.create({
        baseURL: url,
      });
    try {
        const response =  await instance.post(`/api/print/config`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return false;
    }
}

export const printCommand = async (data, url) => {
    const instance = axios.create({
        baseURL: url,
      });
    try {
        const response =  await instance.post(`/api/print/command`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return false;
    }
}

export const printReceipt = async (data, url) => {
    const instance = axios.create({
        baseURL: url,
      });
    try {
        const response =  await instance.post(`/api/print/receipt`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return false;
    }
}