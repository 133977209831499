import axios from "axios";

export const generateURLMedia = (id) => {
    return `${process.env.REACT_APP_URL_MEDIA}${id}`
}

export const uploadFile = async (file, name) => {
    const formData = new FormData();
    formData.append('upload_preset', 'restaurante');
    formData.append('file', file);
    formData.append('api_key', process.env.REACT_APP_API_KEY_CLOUDINARY);

    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_UPLOAD_MEDIA,
      });

    try {
        const response =  await instance.post(`image/upload`, formData);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}