import React, { useState, useEffect, useContext } from 'react'
import { Button, InputGroup, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { getCustomers } from '../../services/customers'
import { deleteOrder, updateOrder } from '../../services/order'
import { getTables } from '../../services/tables'
import { numberToMoney } from '../../utils/locations'
import { AppContext } from '../../containers/Dashboard';
import { printCommand, printReceipt } from '../../services/printLocal'
import { updateBilling } from '../../services/billing'
import { getProducts, updateQuantityProducts } from '../../services/products'
import { useHistory } from 'react-router-dom'

const ModalRegister = ({ showModal, setShowModal, dataModal, setRefresh, refresh, setDataModal, isEdit }) => {

    const [tables, setTables] = useState([]);
    const [customers, setCustomers] = useState([]);

    let history = useHistory();
    const { dataCompany, dataPrint, setDetailsCurrentOrder } = useContext(AppContext);

    useEffect(() => {
        getTables().then(response => {
            setTables(response.data);
        })
        getCustomers().then(response => {
            setCustomers(response.data);
        })
    }, [])

    const handleClose = () => {
        setShowModal(false)
    }

    const handleChangeInput = async ({ target }) => {
        Swal.showLoading();
        let newCustomer = Number(target.value);
        setDataModal({
            ...dataModal,
            customer: {
                id: newCustomer
            }
        })
        await updateOrder(dataModal.id, {
            customer: newCustomer
        })
        Swal.close();
    }

    const handleChangeTable = async ({ target }) => {
        Swal.showLoading();
        const newTable = Number(target.value);
        await updateOrder(dataModal.id, {
            table: newTable
        })
        setRefresh(!refresh);
        Swal.close();
    }

    const handleBill = async () => {
        Swal.showLoading();
        let date = new Date()
        const user = JSON.parse(localStorage.getItem('user'));
        const userName = `${user.first_name} ${user.last_name}`;
        let customer = 'Publico';
        if (dataModal.customer?.id) {
            customer = customers.find(e => e.id === dataModal.customer.id)
        }
        const productsPrint = [];
        dataModal.products.forEach(element => {
            let newProductPrint = {
                name: element.products_id?.name,
                quantity: element.quantity,
                price: numberToMoney(element.products_id?.price),
                total: numberToMoney(element.subtotal)
            }
            productsPrint.push(newProductPrint);
        });
        let orderPrint = {
            config: {
                title: dataCompany.name,
                address: `${dataCompany.address} - ${dataCompany.city}`,
                city: dataPrint.resolution,
                nit: dataCompany.nit,
                phone: dataCompany.phone,
            },
            customer: {
                name: customer?.name ? customer.name : 'Publico'
            },
            sale: {
                number: `${dataPrint.prefix} ${dataPrint.consecutive + 1}`,
                date: `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`,
                total: numberToMoney(dataModal.total),
                employee: userName
            },
            products: productsPrint
        }
        //Imprimir factura
        try {
            await printReceipt(orderPrint, dataPrint.url);
            if (isEdit) {
                //Guardar Factura - Actualizar estado
                await updateOrder(dataModal.id, {
                    status: 'payed',
                    number_billing: `${dataPrint.prefix} - ${dataPrint.consecutive + 1}`,
                })
                //Actualizar nuevo numero de factura
                await updateBilling({
                    consecutive: dataPrint.consecutive + 1
                })
                //Consultar todos los productos
                await getProducts();
                //Descontar cantidades de productos
                let dataQuantity = []
                dataModal.products.forEach(element => {
                    const quantityOrder = {
                        id: element.products_id.id,
                        quantityMinus: element.quantity
                    }
                    dataQuantity.push(quantityOrder)
                })
                await updateQuantityProducts(dataQuantity)

                setRefresh(!refresh)
            }

            Swal.fire(
                'Excelente!',
                'Guardado exitosamente',
                'success'
            )
        } catch (error) {
            Swal.fire(
                'Oops!',
                'Ha ocurrido un error al guardar',
                'error'
            )
        }
        Swal.close();
        handleClose();
    }

    const reprintCommand = async () => {
        let date = new Date(dataModal.date_created);
        Swal.showLoading();
        //print order
        let productsPrint = [];
        dataModal.products.forEach(element => {
            let aditionsPrint = [];
            console.log(element);
            if (element.aditions) {
                let newAditions = element.aditions.split('-');
                aditionsPrint.concat(newAditions);
            }
            if (element.comment) {
                aditionsPrint.push(element.comment);
            }
            let newProduct = {
                name: element.products_id.name,
                quantity: element.quantity,
                aditions: aditionsPrint
            }
            productsPrint.push(newProduct)
        });
        const dataPrinter = {
            customer: {
                name: 'Publico'
            },
            sale: {
                number: 'N/A',
                date: `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`,
                table: `${dataModal?.table.name} - ${dataModal?.table.room.name}`
            },
            products: productsPrint
        }
        await printCommand(dataPrinter, dataPrint.url);
        Swal.close();
    }

    const cancelOrder = async () => {
        Swal.showLoading();
        try {
            await deleteOrder(dataModal.id);
            Swal.fire(
                'Excelente!',
                'Anulado exitosamente',
                'success'
            )
            setRefresh(!refresh)
            handleClose();
        } catch (error) {
            Swal.fire(
                'Oops!',
                'Ha ocurrido un error al guardar',
                'error'
            )
        }
    }

    const handleAdd = () => {
        console.log(dataModal)
        setDetailsCurrentOrder({
            table: dataModal.table,
            idOrder: dataModal.id,
            currentProducts: dataModal.products,
            total: dataModal.total
        })
        history.push('/order');
    }

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Pedido' : `Factura # ${dataModal?.number_billing ? dataModal.number_billing : ''}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    dataModal.table && (
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="price">Cliente</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <select
                                            onChange={isEdit ? handleChangeInput : () => { }}
                                            className="form-control"
                                            value={dataModal.customer?.id ? dataModal.customer.id : ''}
                                            disabled={!isEdit}
                                        >
                                            {isEdit ? <option value="">Seleccione</option> : null}

                                            {
                                                customers?.map((customer, index) => (
                                                    <option key={index} value={customer.id}>{customer.fullname}</option>
                                                ))
                                            }
                                        </select>
                                    </InputGroup>
                                </div>
                                <div className="col-md-6">
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{isEdit ? 'Cambiar de Mesa' : 'Mesa'}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <select
                                            onChange={isEdit ? handleChangeTable : () => { }}
                                            className="form-control"
                                            value={dataModal.table.id}
                                            disabled={!isEdit}
                                        >
                                            {isEdit ? <option value="">Seleccione</option> : null}
                                            {
                                                tables?.map((table, index) => (
                                                    <option key={index} value={table.id}>{`${table.room?.name} - ${table.name}`}</option>
                                                ))
                                            }
                                        </select>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-bordered table-sm">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">Cant.</th>
                                                <th scope="col">Producto</th>
                                                <th scope="col" className="text-right">Precio</th>
                                                <th scope="col" className="text-right">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataModal.products?.map((product, index) => (
                                                    <tr key={index}>
                                                        <td>{product.quantity}</td>
                                                        <td>{product.products_id?.name}</td>
                                                        <td className="text-right">{numberToMoney(product.products_id?.price)}</td>
                                                        <td className="text-right">{numberToMoney(product.subtotal)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className="font-weight-bold">
                                                <td colSpan={3} className="text-right register__total">Total</td>
                                                <td className="text-right text-primary register__total">{numberToMoney(dataModal.total)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                {
                    isEdit ?
                        <>
                            <Button variant="danger" onClick={cancelOrder}>
                                Anular Pedido
                            </Button>
                            <Button variant="primary" onClick={handleAdd}>
                                Agregar Productos
                            </Button>
                        </>
                        : null
                }
                <Button variant="warning" onClick={reprintCommand}>
                    Reimprimir Comanda
                </Button>
                <Button variant="success" onClick={handleBill}>
                    {isEdit ? 'Facturar y Guardar' : 'Reimprimir Factura'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalRegister