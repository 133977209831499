import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useForm from '../../../hooks/useForm';
import { getOrdersPayed } from '../../../services/order';
import { numberToMoney, parsedDate } from '../../../utils/locations';
import DataTables from '../../DataTables'
import Export from '../../excel/Export';
import ModalRegister from '../../register/ModalRegister';

const ReportDaily = () => {

    const [sales, setSales] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const columns = [
        {
            dataField: 'date_created',
            text: 'Fecha',
            sort: true,
            formatter: (cellContent, row) => {
                let date = new Date(row.date_created);
                return (
                    <>
                        {`${date.toLocaleDateString()} - ${date.toLocaleTimeString('en-US', { hour12: true })}`}
                    </>
                )
            }
        },
        {
            dataField: 'number_billing',
            text: 'Número',
            sort: true
        },
        {
            dataField: 'table.name',
            text: 'Mesa',
            sort: true
        },
        {
            dataField: 'user_created.first_name',
            text: 'Empleado',
            sort: true
        },
        {
            dataField: 'total',
            text: 'Total',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => { handlePrint(row) }}>
                            <i className="fa fa-print"></i>
                        </span>
                    </>
                );
            }
        },
    ];

    const excelColumns = [
        {
            text: 'Fecha',
            dataField: (col) => {
                let date = new Date(col.date_created);
                return `${date.toLocaleDateString()} - ${date.toLocaleTimeString('en-US', { hour12: true })}`
            }
        },
        {
            dataField: 'number_billing',
            text: 'Número',
        },
        {
            dataField: (col) => {
                return col.table.name
            },
            text: 'Mesa',
        },
        {
            dataField:
                (col) => {
                    return col.user_created.first_name
                },
            text: 'Empleado',
        },
        {
            dataField: 'total',
            text: 'Total',
        }
    ]

    const [dataForm, handleChangeInput] = useForm({
        date_start: parsedDate(),
        date_end: parsedDate(),
    })

    const handleSearch = async () => {
        Swal.showLoading();
        let dateStart = new Date(dataForm.date_start);
        dateStart.setMinutes(dateStart.getMinutes() + dateStart.getTimezoneOffset())
        let dateEnd = new Date(dataForm.date_end);
        dateEnd.setMinutes(dateEnd.getMinutes() + dateEnd.getTimezoneOffset())
        const response = await getOrdersPayed(parsedDate(dateStart), parsedDate(dateEnd))
        console.log(response.data)
        setSales(response.data);
        Swal.close();
    }

    useEffect(() => {
        handleSearch();
    }, [])

    const handlePrint = (dataOrder) => {
        setDataModal(dataOrder);
        setShowModal(true);
    }

    return (
        <>
            <ModalRegister
                setShowModal={setShowModal}
                showModal={showModal}
                dataModal={dataModal}
            />
            <h1 className="h3 mb-2 text-gray-800">Reporte de Ventas</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Listado de ventas</h6>
                    <Export
                        data={sales}
                        columns={excelColumns}
                        fileName="Reporte de Ventas"
                        sheetName={`${dataForm.date_start}__${dataForm.date_end}`}
                    />
                </div>
                <div className="card-body">
                    <div className="d-flex">
                        <InputGroup size="sm" className="mb-3 mr-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="date_start">Fecha Inicial</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                aria-label="date_start"
                                aria-describedby="date_start"
                                name="date_start"
                                value={dataForm.date_start}
                                onChange={handleChangeInput}
                                max={dataForm.date_end}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3 ml-2 mr-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="date_end">Fecha Final</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                aria-label="date_end"
                                aria-describedby="date_end"
                                name="date_end"
                                value={dataForm.date_end}
                                onChange={handleChangeInput}
                                min={dataForm.date_start}
                            />
                        </InputGroup>
                        <button className="btn btn-primary btn-sm ml-2" onClick={handleSearch}>
                            <span>Buscar</span>
                        </button>
                    </div>
                    <div className="d-flex">
                        <div className='alert alert-primary'>
                            <strong>Total Ventas: {sales.length ? numberToMoney(sales.reduce((a, b) => Number(a) + Number(b.total), 0)) : numberToMoney(0)}</strong>
                        </div>
                        <div className='alert alert-primary ml-2'>
                            <strong>Cantidad Tickets: {sales.length ? sales.length : 0}</strong>
                        </div>
                    </div>
                    <DataTables data={sales} columns={columns} />
                </div>
            </div>
        </>
    )
}

export default ReportDaily